<template>
	<div>
		<div class="js-copy js-content">
			<div class="ac_pc">
				<img
					style="width: 100%"
					src="https://res.metaera.hk/resources/assets/images/home/language_en_pc_bg.png"
					alt=""
				/>
			</div>
			<div class="ac_phone">
				<img
					style="width: 100%"
					src="https://res.metaera.hk/resources/assets/images/home/language_en_app_bg.png"
					alt=""
				/>
			</div>
		</div>
		<div class="language_en_goAction pointer" @click="goAction"></div>
		<div class="language_en_goBack pointer" @click="goBcak"></div>
	</div>
</template>

<script>
let hasPath;
import router from "../../router";

export default {
	name: "",
	data() {
		return { osafai: false, olead: false };
	},
	created() {},
	methods: {
		goAction() {
			window.open("https://twitter.com/MetaEra_Media", "");
		},
		goBcak() {
			if (hasPath == "/") {
				this.$router.push("/");
			} else {
				this.$router.go(-1);
			}
		},
	},
};

router.afterEach((to, from, next) => {
	hasPath = from.name;
});
</script>

<style scoped>
.js-copy {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
}

.language_en_goAction {
	/* background: red; */
	position: absolute;
	margin-top: 0px;
	top: calc(100vw * 0.33);
	width: calc(100vw * 0.8);
	left: calc(100vw * 0.1);
	height: calc(100vw * 0.06);
	text-align: center;
}

.language_en_goBack {
	/* background: red; */
	position: absolute;
	margin-top: 0px;
	top: calc(100vw * 0.42);
	width: calc(100vw * 0.8);
	left: calc(100vw * 0.1);
	height: calc(100vw * 0.06);
	text-align: center;
}

.ac_pc {
	display: block;
}
.ac_phone {
	display: none;
}

@media screen and (max-width: 900px) {
	.ac_pc {
		display: none;
	}
	.ac_phone {
		display: block;
	}
	.js-copy {
		position: absolute;
		top: 0px;
		left: 0;
		width: calc(100vw);
		/* background: #000; */
		margin: 0 auto;
	}

	.language_en_goAction {
		position: absolute;
		/* background: red; */
		margin-top: 0px;
		top: calc(100vw * 1.18);
		width: calc(100vw * 0.8);
		left: calc(100vw * 0.1);
		height: calc(100vw * 0.14);
		text-align: center;
	}
	.language_en_goBack {
		position: absolute;
		/* background: red; */
		margin-top: 0px;
		top: calc(100vw * 1.4);
		width: calc(100vw * 0.8);
		left: calc(100vw * 0.1);
		height: calc(100vw * 0.14);
		text-align: center;
	}
}
</style>
